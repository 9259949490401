

























































import CommonProfileImage from 'common-modules/src/components/CommonProfileImage.vue';
import Vue from 'vue';
import { Prop } from 'vue-property-decorator';
import Component from 'vue-class-component';
import jwlDiscussionGradingForm from './jwlDiscussionGradingForm.vue';
import { ExtendedRunTask } from '@/store/interface/Task';
import { DiscussionGrade, DiscussionPost } from '@/store/interface/Discussion';
import { Submission } from '@/store/interface/Grade';

const JwlButton = () => import('@/components/JwlButton.vue');
const CommonDate = () => import('common-modules/src/components/CommonDate.vue');

@Component({
  components: {
    CommonProfileImage,
    jwlDiscussionGradingForm,
    JwlButton,
    CommonDate,
  },
})
export default class JwlDiscussionGrading extends Vue {
  @Prop(Array)
  studentGrades!: DiscussionGrade[];

  @Prop(Array)
  studentPosts!: DiscussionPost[];

  @Prop(Object)
  jwlTask!: ExtendedRunTask;

  activeStudent: string | null = null;
  grades: DiscussionGrade[] = [];
  mobileOpen = false;

  setActiveStudent (data: DiscussionGrade): void {
    const lmsId = data.student.id;
    if (this.activeStudent === lmsId) {
      this.activeStudent = null;
      this.$emit('active-student', null);
    } else {
      this.activeStudent = lmsId;
      this.$emit('active-student', lmsId);
    }
    this.mobileOpen = false;
  }

  activeGradeClass (lmsId: string): Record<string, boolean> {
    return {
      'jwl-discussion-grading__student--open': this.activeStudent === lmsId,
    };
  }

  localizedDate (dateString: string): string {
    const date = new Date(dateString);
    return date.toUTCString();
  }

  gradeSubmissionClass (data: DiscussionGrade): Record<string, boolean> {
    let taskState;
    const postsOrComments = this.studentPosts.filter((post) => post.author.lmsId === data.student.id);

    if (postsOrComments.length > 0 || data.grade) {
      if (data.grade) {
        if (data.grade.date) {
          if (this.jwlTask.maxScore > 0 && Number(data.grade.score) === 0) {
            taskState = '0-grade';
          } else {
            taskState = 'grade';
          }
        } else {
          taskState = 'unpublished';
        }
      } else {
        taskState = 'ungraded';
      }
    } else {
      taskState = 'nothing';
    }

    return {
      'jwl-discussion-grading__student--0-grade': taskState === '0-grade',
      'jwl-discussion-grading__student--grade': taskState === 'grade',
      'jwl-discussion-grading__student--unpublished': taskState === 'unpublished',
      'jwl-discussion-grading__student--ungraded': taskState === 'ungraded',
      'jwl-discussion-grading__student--nothing': taskState === 'nothing',
    };
  }

  updateGrade (data: Submission): void {
    const index = this.grades.findIndex((grade) => grade.student.id === data.student);
    if (index >= 0) {
      this.grades[index].id = data.id;
      this.grades[index].grade = data.grade;
    }
  }

  openMobile (): void {
    this.mobileOpen = !this.mobileOpen;
  }

  get mobileOpenClass (): Record<string, boolean> {
    return {
      'jwl-discussion-grading__container--open': this.mobileOpen,
    };
  }

  created (): void {
    this.grades = this.studentGrades;
  }
}
